import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import RenderArticleContent from "./RenderArticleContent"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import DoubleRightArrowIcon from '../icons/DoubleRightArrowIcon'
import DoubleLeftArrowIcon from "../icons/DoubleLeftArrowIcon"
import useArticleContent from "../../hooks/useArticleContent"
import Stats from "./ArticleContent/Stats/Stats"
import { DisplaySettingsContext } from "../../store/displaySettingsContext"
import useArticleInfo from "../../hooks/useArticleInfo"

export default function Article() {

  const [isCollapsed,setIsCollapsed] = useState(false)
  const [hideSide,setHideSide] = useState(true)

  const params = useParams()
  const windowDimensions = useWindowDimensions()
  const articleContent = useArticleContent(params.id)
  const [articleInfo, articleInfoLoading] = useArticleInfo(params.id)
  const { navHeight } = useContext(DisplaySettingsContext)

  useEffect(() => {
    if(!articleInfoLoading){
      document.title = articleInfo.title + ' - Doodad Capital'
    }
  },[articleInfo,articleInfoLoading])

  const heightOfContent = windowDimensions.height - navHeight;

  const style = {
    "height": heightOfContent
  }

  const collapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  useEffect(() => {
    // setHideSide((windowDimensions.breakpoint <= 1 ? true : false))
    setHideSide(true)
  }, [windowDimensions])
  
  
  const articleWidthClass = isCollapsed ? '' : '';
  const statsDisplayClass = isCollapsed ? ('w-10' + (hideSide ? " hidden" : "")) : ('w-[750px]' + (hideSide ? " hidden" : ""))
  const articleContentsClass = isCollapsed ? 'px-4 md:px-6 lg:px-10 w-3/4' : (hideSide ? ' px-4 md:px-6 lg:px-32' : 'px-5')
  const iconClass = isCollapsed ? 'justify-center' : 'flex-row-reverse'

  return (
    <div style={style} className="Article justifycenter w-full flex overflow-scroll noScroll py-5 ">
      <div className={" h-full w-full flex justify-center shrink transition-all ease-in-out " + articleWidthClass}>
        <div className={" ArticleContentContainer transition-all ease-in-out  h-full w-full  " + articleContentsClass} >
          <RenderArticleContent markdown={articleContent.markdown} />
        </div>
      </div>
      <div className={" pt-2 pl-2 grow overflow-scroll noScroll border-l-slate-800 border-l-2 transition-all ease-in-out " + statsDisplayClass}>
        <div className={"tran w-full"}>
          <div className={"w-full flex " + iconClass}>
            <div className="h-4 cursor-pointer" onClick={collapse}>
              { isCollapsed ? <DoubleLeftArrowIcon /> : <DoubleRightArrowIcon /> }
            </div>
          </div>
          <div className={(isCollapsed ? "hidden" : "block") + " w-full"}>
            <Stats articleID={params.id} />
          </div>
        </div>
      </div>
    </div>
  )
}