
import { useState, useEffect } from "react";
import { articleDoc } from '../config/fbConfig'
import { getDoc } from "firebase/firestore";

const useArticleInfo = (articleID) => {
  const [data, setData] = useState({});
  const [loading,setLoading] = useState(true)

  useEffect(() => {

    setLoading(true)

    const articleDocRef = articleID ? articleDoc(articleID) : null

    getDoc(articleDocRef).then(docSnap => {
      setData(docSnap.data())
      setLoading(false)
    })

    
  }, [articleID]);

  return [data,loading];
};

export default useArticleInfo;