import { useNavigate } from "react-router-dom"
import moment from "moment"
import main_logo from '../../images/main_logo.svg'
import useWindowDimensions from "../../hooks/useWindowDimensions"


export default function ArticleListItem({ article }) {

  const navigate = useNavigate()
  const { breakpoint } = useWindowDimensions()

  const description = article.lead
  const title = article.title

  var previewTruncateLength = 500
  if(breakpoint === 0){
    previewTruncateLength = 100
  }else if(breakpoint === 1){
    previewTruncateLength = 100
  }
  const truncatedDescription = description.substring(0,previewTruncateLength) + '...'

  var titleTruncateLength = 40
  const titleTooLong = title.length > titleTruncateLength
  const truncatedTitle = title.substring(0,titleTruncateLength) + (titleTooLong ? '...' : '')



  

  const click = () => {
    navigate("/articles/" + article.id)
  }
  
  const imageSrc = article.imageURL ? article.imageURL : main_logo;

  return (

  <div onClick={click} className=" w-full md:w-2/3 px-2 md:pl-20 ">
    <div className="flex w-full md:animateHover cursor-pointer bg-slate-300 border-black border-2 rounded-lg">
      <div className="h-40">
      <img className="h-40 max-w-40 bg-white rounded-l" src={imageSrc} alt="" />
      </div>
      <div className="flex content-center w-full md:w-[1000px] h-40">
        <div className="  w-full text-primary" >
          <div className="p-2">
            <p className="text-lg md:text-xl">{ truncatedTitle }</p>
            <p className="">{moment.unix(article.createdDate.seconds).format("M/DD/YYYY")}</p>
            <p className="text-sm md:text-base">{truncatedDescription}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}