
import { useState, useEffect } from "react";
import { auth, chirpsCollection } from '../config/fbConfig'
import { getDocs, query, where, orderBy } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

const useChirps = () => {
  const [data, setData] = useState([]);
  const [loading,setLoading] = useState(true)
  const [FBuser] = useAuthState(auth);

  useEffect(() => {

    var q;

    if(FBuser !== null){
      q = query(chirpsCollection(), orderBy("createdDate","desc"))
    }else{
      q = query(chirpsCollection(), where("isLive","==",true), orderBy("createdDate","desc"))
    }

    // const q = query(chirpsCollection(), orderBy("createdDate","desc"))

    const newData = []

    setLoading(true)
    getDocs(q).then(querySnapshot => {
      const docs = querySnapshot.docs
      for(var i = 0; i < docs.length; i++){
        newData.push({...docs[i].data(),id:docs[i].id})
      }
      setData(newData)
      setLoading(false)
    })


    
  }, [FBuser]);

  return [data,loading];
};

export default useChirps;