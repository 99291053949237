import React from "react"

export default function ArticleTitle({ children }) {


  return (
    <>
      <h1 className="">{ children }</h1>
    </>
  )
}