import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function getBreakpoint(dimensions) {
  const { width } = dimensions;

  if(width < 768){
    return 0;
  }else if(width < 1024){
    return 1;
  }else if(width < 1280){
    return 2
  }else if(width < 1536){
    return 3;
  }else{
    return 4;
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({
    ...getWindowDimensions(),
    breakpoint: 0 //0:sm, 1:md, 2:lg, 3:xl, 4:2xl
});

  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions()
      const breakpoint = getBreakpoint(dimensions)
      setWindowDimensions({...dimensions, breakpoint});
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}