import useArticleInfo from "../../../../hooks/useArticleInfo";
import moment from "moment";
import { chartData } from "../../../../temp";
import PriceChart from "./PriceChart";

export default function Stats({ articleID }) {

  const [articleInfo] = useArticleInfo(articleID)

  const articleDateText = articleInfo && articleInfo.createdDate ? moment.unix(articleInfo.createdDate.seconds).format("M/DD/YYYY") : moment(Date.now()).format("M/DD/YYYY")

  const getData = () => {
    if(articleInfo && articleInfo.chartData){
      return JSON.parse(articleInfo.chartData)
    }
    return chartData;
  }

  return (
    <div>
      <p className="text-3xl">Quick Look</p>
      <p>As of close on {articleDateText}</p>
      { articleInfo.chartData ? 
      <div className="w-full">
        <p className="text-xl mt-5">1 Year Chart</p>
        <PriceChart data={getData()} />
      </div>
      : null }
      <p className="text-xl mt-5">Key Stats</p>
      <p>P/E: 23.4</p>
      <p>Market Cap: $495.43B</p>
      <p>Current Ratio: 1.3</p>
      <p>Debt/Equity: 10</p>
      <p className="text-xl mt-5">Trends</p>
      <p>1 month: +19%</p>
      <p>3 month: +23%</p>
      <p>6 month: -45%</p>
      <p className="text-xl mt-5">Relevant tickers</p>
      <p>GOOG</p>
      <p>AAPL</p>
    </div>
  )
}