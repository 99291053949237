
export default function ArticleDisclaimer() {


  return (
    <p className="ArticleDisclaimer"> <span className="italic"><span className="font-bold">Disclaimer:</span> We are not registered investment, tax or legal advisors
    or brokers and therefore cannot promise or guarantee any financial returns from our opinions on this page or site.
    The content of this article is based on our own personal thoughts and research and you should do your own due
    diligence before making any investment decisions. This article may be structured as such, but it is not financial or
    investment advice. While we do make our best effort to ensure that all information in our articles is accurate and up
    to date, occasionally unintended errors or misprints may occur. One or more contributors to this article may have
    personal positions in equities mentioned in either the past, present or possibly future. Remember that all
    investments in the market face the risk of going to $0. The contributors of this article have no business or personal
    relationship with any company mentioned in the above article.</span></p>
  )
} 
