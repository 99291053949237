import ArticlesList from "./ArticlesList";


export default function Articles() {

  return (
    <div className="h-full w-full">
      <ArticlesList />
    </div>
  )
}