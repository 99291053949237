import React, { useContext, useEffect } from 'react';
import main_logo from '../../images/main_logo.svg'
import { NavLink } from 'react-router-dom';
import NavBarLink from './NavBarLink';
import { DisplaySettingsContext } from '../../store/displaySettingsContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';


export default function Nav() {

  const { setNavHeight } = useContext(DisplaySettingsContext)
  const { breakpoint } = useWindowDimensions()

  useEffect(() => {
    if(breakpoint === 0){
      setNavHeight(64)
    }else if(breakpoint === 1){
      setNavHeight(80)
    }else if(breakpoint === 2){
      setNavHeight(96)
    }
    else{
      setNavHeight(112)
    }
  }, [breakpoint,setNavHeight])
  
  //h-28 = 112 px

  const navLinks = [
    {title: 'Home', to: '/', activeMatch: '/', matchChars: 0 },
    {title: 'Articles', to: '/articles', activeMatch: '/articles', matchChars: 9 },
    {title: 'Ideas', to: '/ideas', activeMatch: '/ideas', matchChars: 6 }
  ]

  return (
    <div className="Nav h-16 md:h-20 lg:h-24 xl:h-28 w-screen px-5 py-1 md:py-1 xl:py-2 text-lg font-bold">
      <ul className="nav-list w-full flex justify-between">
        <li className=""><NavLink to="/"><img className="h-14 md:h-[72px] lg:h-[5.5rem] xl:h-24" src={main_logo} alt="logo"></img></NavLink></li>
        <div className="flex space-x-3 items-center ">
          { navLinks && navLinks.map((navLink,i) => {
            return <NavBarLink key={i} linkConfig={navLink} />
          })}
        </div>
      </ul>
    </div>
  )
}