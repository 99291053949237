import React, { useState } from "react";

export const DisplaySettingsContext = React.createContext();


export const DisplaySettingsProvider = ({ children }) => {


  const [navHeight,setNavHeight] = useState(112);

  return (
    <DisplaySettingsContext.Provider value={{ navHeight, setNavHeight }}>{children}</DisplaySettingsContext.Provider>
  );
};
