import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from "react-router-dom"
import { articleContentDoc, articleDraftDoc, auth } from '../../../config/fbConfig';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { DisplaySettingsContext } from '../../../store/displaySettingsContext';

import { MDXEditor, headingsPlugin, UndoRedo, BoldItalicUnderlineToggles, toolbarPlugin, BlockTypeSelect, CreateLink, linkDialogPlugin, linkPlugin, imagePlugin, InsertImage, ListsToggle, listsPlugin, GenericDirectiveEditor, directivesPlugin, DiffSourceToggleWrapper, diffSourcePlugin, ButtonOrDropdownButton, ConditionalContents, InsertTable, tablePlugin } from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import DisclaimerButton from './DisclaimerButton';
import { getDoc, setDoc } from 'firebase/firestore';



export default function ArticleWriterNew() {

  const [FBuser,FBuserLoading] = useAuthState(auth);
  const nav = useNavigate()
  const windowDimensions = useWindowDimensions()
  const { navHeight } = useContext(DisplaySettingsContext)
  const ref = useRef()
  const [dbLoading,setDbLoading] = useState(false)
  const [currentContent,setCurrentContent] = useState("")
  const [currentArticleID,setCurrentArticleID] = useState("")


  const articleIDChange = (e) => {
    setCurrentArticleID(e.target.value)
  }


  const contentChange = (newContent) => {
    setCurrentContent(newContent)
  }


  const saveDraft = async (articleID) => {

    const finalOutput =  currentContent.replace(/\n/g, '#NEWLINE#');

    articleID = currentArticleID
    const draftDocRef = articleDraftDoc(articleID)

    setDbLoading(true)
    await setDoc(draftDocRef,{markdown: finalOutput},{ marge: true })
    setDbLoading(false)

    console.log(finalOutput)

  }

  const loadDraft = async () => {
    const articleID = currentArticleID
    const draftDocRef = articleDraftDoc(articleID)
    
    setDbLoading(true)
    const doc = await getDoc(draftDocRef)
    setDbLoading(false)

    console.log(doc.data())

    const cleanedMarkdown = doc.data().markdown.replace(/#NEWLINE#/g, '\n');
    ref.current.setMarkdown(cleanedMarkdown)
  }

  const loadCurrent = async () => {
    const articleID = currentArticleID
    const docRef = articleContentDoc(articleID)
    
    setDbLoading(true)
    const doc = await getDoc(docRef)
    setDbLoading(false)

    console.log(doc.data())

    const cleanedMarkdown = doc.data().markdown.replace(/#NEWLINE#/g, '\n');
    ref.current.setMarkdown(cleanedMarkdown)
  }


  const heightOfContent = windowDimensions.height - navHeight;
  const style = {
    "height": heightOfContent
  }


  useEffect(() => {
    if(FBuserLoading === false && FBuser == null){
      nav('/')
    }
  }, [FBuser,FBuserLoading,nav])

  const DisclaimerDirectiveDescriptor = {
    name: 'disclaimer',
    testNode(node) {
      return node.name === 'disclaimer'
    },
    // set some attribute names to have the editor display a property editor popup.
    attributes: [],
    // used by the generic editor to determine whether or not to render a nested editor.
    hasChildren: false,
    Editor: GenericDirectiveEditor
  }

  const initialMarkdown = `
  # Title (TICK)
  Intro paragraph
  ::disclaimer
  `

  return (
    <div style={style} className="ArticleWriter">
      <MDXEditor ref={ref} onChange={contentChange} markdown={initialMarkdown} contentEditableClassName="ArticleContentContainer px-32 font-mukta pb-10" className="h-full overflow-scroll" plugins={[
        headingsPlugin(),
        linkDialogPlugin(),
        linkPlugin(),
        imagePlugin(),
        listsPlugin(),
        directivesPlugin({ directiveDescriptors: [DisclaimerDirectiveDescriptor] }),
        diffSourcePlugin(),
        tablePlugin(),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <DiffSourceToggleWrapper>
              <ConditionalContents
                options={[
                  { when: (editor) => dbLoading === true, contents: () => null},
                  {
                    fallback: () => (
                      <>
                        <UndoRedo />
                        <BoldItalicUnderlineToggles />
                        <BlockTypeSelect />
                        <CreateLink />
                        <InsertImage />
                        <ListsToggle />
                        <InsertTable />
                        <ButtonOrDropdownButton
                          items={[{value:"1",label:"Save Draft"}, {value:"2",label:"Load Draft"}, {value:"3",label:"Load Current"},{value:"4",label:"Set Draft Live"}]}
                          onChoose={(choice) => {
                            if(choice === '1'){
                              saveDraft()
                              return;
                            }else if(choice === '2'){
                              loadDraft()
                            }else if(choice === '3'){
                              loadCurrent()
                            }
                          }}
                        >
                          Article
                        </ButtonOrDropdownButton>
                        <DisclaimerButton />
                        <input className="ml-2" type="text" value={currentArticleID} onChange={articleIDChange} />
                      </>
                    )
                  }
                ]}
              />
              </DiffSourceToggleWrapper>
            </>
          )
        })
      ]} />
    </div>
  )
}