import { LineChart } from '@mui/x-charts/LineChart';
import { getHexFromTailwindColor } from '../../../../styles/colorClasses';
import moment from 'moment'
import { useEffect, useState } from 'react';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';


export default function PriceChart(props) {
  const color = getHexFromTailwindColor('primary')

  const [data,setData] = useState({x:[1,2,3],y:[1,2,3]})
  const windowDimensions = useWindowDimensions()

  useEffect(() => {
    setData(props.data)
  }, [windowDimensions,props.data])



  var xScaleType = 'linear'
  var xValueFormatter = (v) => v.toString();
  var xMin = null
  var xMax = null

  if(data.xScaleType === 'time'){

    xScaleType = 'time'
    xValueFormatter = (v) => {
      const retVal = moment(v).format('MM-DD-YY')
      return retVal
    }

    const newX = []
    for(var i = 0;i<data.x.length; i++){
      const date = moment(data.x[i], "MM/DD/YYYY").toDate()
      if(xMin === null || date < xMin) xMin = date;
      if(xMax === null || date > xMax) xMax = date;
      newX.push(date)
    }
    data.x = newX
  }

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format

  return (
    <div className="w-full">
      <LineChart
        xAxis={[{ data: data.x, xScaleType, valueFormatter: xValueFormatter, tickNumber: 5, min:xMin, max:xMax }]}
        series={[
          {
            curve: "linear",
            showMark: false,
            data: data.y,
            color,
            label:data.label,
            valueFormatter: (v) => currencyFormatter(v)
          },
        ]}
        // width={475}
        height={500}

      />
    </div>
  )
}