import moment from 'moment'
import squareLogo from '../../images/squareLogo.png'
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, chirpDoc } from '../../config/fbConfig';
import { deleteDoc, updateDoc } from 'firebase/firestore';


export default function QuickTake({ chirp }) {

  const [FBuser,FBuserLoading] = useAuthState(auth);


  const dateText = moment.unix(chirp.createdDate.seconds).format("MMMM Do, YYYY")

  const editClick = () => {
    if(!FBuser && !FBuserLoading) return;
    const operation = window.prompt("Enter operation: 'edit', 'setLive', 'setNotLive', 'permanent delete'. There will be no confirmation.")
    if(operation === null || operation === '') return;

    if(operation === 'setNotLive'){
      updateDoc(chirpDoc( chirp.id ),{
        isLive: false
      }).then(() => {
        window.location.reload();
      })
    }

    if(operation === 'setLive'){
      updateDoc(chirpDoc( chirp.id ),{
        isLive: true
      }).then(() => {
        window.location.reload();
      })
    }

    if(operation === 'edit'){
      const newContent = window.prompt("Enter new content",chirp.content)
      if(newContent !== null && newContent !== ""){
        updateDoc(chirpDoc(chirp.id), {
          content: newContent
        }).then(() => {
          window.location.reload();
        })
      }
    }

    if(operation === 'permanent delete'){
      const confirmation = window.prompt("Write 'im sure' if you are sure")
      if(confirmation === 'im sure'){
        deleteDoc(chirpDoc(chirp.id)).then(() => {
          window.location.reload();
        })
      }
    }

  }


  return (
    <div className={"w-full h-64 rounded-lg p-5 text-3xl relative " + (chirp.isLive === false ? "bg-gray-600" : "bg-white") + (FBuser ? ' cursor-pointer' : '') } onClick={editClick}>
      <div className="flex space-x-2 items-center mb-2">
        <img className="w-6 h-6 border-2 border-black" src={squareLogo} alt="Doodad" />
        <p className="text-lg ">Doodad</p>
      </div>
      <p >{chirp.content}</p>
      <div className="absolute bottom-2 right- left-0 text-base text-gray-400 flex justify-between w-full px-5">
        <div className="flex space-x-2">
          { chirp.tickers && chirp.tickers.map((ticker,i) => {
            return <Ticker ticker={ticker} last={i === chirp.tickers.length - 1 ? true : false} key={i} />
          })}
        </div>
        <p>{dateText}</p>
      </div>
    </div>
  )
}


function Ticker({ticker, last}){


  return(
    <a className="" href={"https://finance.yahoo.com/quote/" + ticker} target="_blank" rel="noreferrer">{ticker + (last ? " " : ", ")}</a>
  )

}