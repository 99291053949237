import { useState } from "react"
import QuickTake from "../components/QuickTakes/QuickTake"
import moment from "moment"
import { addDoc } from "firebase/firestore"
import { chirpsCollection } from "../config/fbConfig"


export default function ChirpCreator() {

  const [chirp,setChirp] = useState({
    content: "New Chirp",
    tickersText: "A, B",
    tickers: ["A","B"],
    createdDate: {seconds: moment().unix() }
  })


  const contentChange = (e) => {
    setChirp({
      ...chirp,
      content: e.target.value
    })
  }


  const tickersChange = (e) => {
    const newText = e.target.value

    var newArray = newText.replace(/\s/g,'').split(",")

    setChirp({
      ...chirp,
      tickersText: newText,
      tickers: newArray
    })
  }

  const postChirp = () => {
    console.log('post')
    addDoc(chirpsCollection(),{
      content: chirp.content.trim(),
      tickers: chirp.tickers,
      isLive: false,
      createdDate: moment().toDate()
    }).then(() => {
      console.log('added')
    })
  }




  return (
    <div className="mt-5">
      <p className="text-2xl">Chirp Creator</p>
      <div className=" flex space-x-5">
        <div className="chirpEditor">
          <div className="flex flex-col">
            <p>Chirp</p>
            <textarea type="text" className="textInput w-96 h-40 p-1" value={chirp.content} onChange={contentChange} />
          </div>
          <div className="flex flex-col mt-3">
            <p>Tickers</p>
            <input type="text" className="textInput w-40 px-1" value={chirp.tickersText} onChange={tickersChange} />
          </div>
          <button className="btn mt-2" onClick={postChirp} >Post</button>
        </div>
        <div className="chirpPreview w-[750px]">
          <QuickTake chirp={chirp} />
        </div>
      </div>
    </div>
  )
}