import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";


const PageTitles = () => {

  const loc = useLocation();

  useEffect(() => {

    const pageNames = [
      // { name: 'Home', path: '/', matchType: 'exact' },
      { name: 'Articles', path: '/articles', matchType: 'exact' },
      { name: 'Chirps', path: '/chirps', matchType: 'exact' },
      // { name: 'Budget Planner', path: '/budget/planner', matchType: 'start' },
    ]

    //change page title
    var newPageTitle = 'Doodad Capital'

    for(var i = 0; i< pageNames.length; i++){
      const pageName = pageNames[i]

      var isSame = false
      if(pageName.matchType === 'start') isSame = loc.pathname.startsWith(pageName.path)
      if(pageName.matchType === 'exact') isSame = loc.pathname === pageName.path


      if(isSame){
        if(pageName.name) newPageTitle = pageName.name + ' - ' + newPageTitle
        break;
      }

    }

    document.title = newPageTitle

  }, [loc]);
  return (<></>);
};
 
export default PageTitles;