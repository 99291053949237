import Nav from "./Nav/Nav";
import { Routes, Route } from "react-router-dom";
import Home from './Home'
import About from './About'
import Articles from './Articles/Articles'
import Article from './Articles/Article'
import Dashboard from './Dashboard'
import useWindowDimensions from "../hooks/useWindowDimensions";
import ArticleWriter from "./Articles/ArticleWriter/ArticleWriter";
import { useContext } from "react";
import { DisplaySettingsContext } from "../store/displaySettingsContext";
import ArticleWriterNew from "./Articles/ArticleWriter/ArticleWriterNew";
import QuickTakes from "./QuickTakes/QuickTakes";
import PageTitles from "./PageTitles";
import Admin from "../Admin/Admin";


export default function Main() {

  const windowDimensions = useWindowDimensions()
  const { navHeight } = useContext(DisplaySettingsContext)

  const heightOfContent = windowDimensions.height - navHeight;

  const style = {
    "height": heightOfContent
  }
  
  return (
    <div className="flex flex-col h-screen">
      <PageTitles />
      <div className="">
        <Nav />
      </div>
      <div style={style} className="overflow-scroll w-screen">
        <Routes>
            <Route path="/*" element={<Home />} />
            <Route path="/about" element={<About />}/>
            <Route path="/articles" element={<Articles />}/>
            <Route path="/articles/:id" element={<Article />}/>
            <Route path="/dashboard" element={<Dashboard />}/>
            <Route path="/admin" element={<Admin />}/>
            <Route path="/writer" element={<ArticleWriter />}/>
            <Route path="/writernew" element={<ArticleWriterNew />}/>
            <Route path="/ideas" element={<QuickTakes />}/>
          </Routes>
      </div>
    </div>
  )
}