import { useContext, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from "react-router-dom"
// import { useParams } from "react-router-dom"
import { auth } from '../../../config/fbConfig';
import { useState } from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import RenderArticleContent from '../RenderArticleContent';
import DoubleLeftArrowIcon from '../../icons/DoubleLeftArrowIcon';
import DoubleRightArrowIcon from '../../icons/DoubleRightArrowIcon';
import { DisplaySettingsContext } from '../../../store/displaySettingsContext';

export default function ArticleWriter() {

  const [FBuser,FBuserLoading] = useAuthState(auth);
  const nav = useNavigate()
  // const params = useParams()
  const windowDimensions = useWindowDimensions()
  const [markdown,setMarkdown] = useState("::chart{data=\"{'x':[1,2,3],'y':[1,2,3]}\"}")
  const [isCollapsed,setIsCollapsed] = useState(false)
  const { navHeight } = useContext(DisplaySettingsContext)

  const heightOfContent = windowDimensions.height - navHeight;

  const style = {
    "height": heightOfContent
  }

  useEffect(() => {
    if(FBuserLoading === false && FBuser == null){
      nav('/')
    }
  }, [FBuser,FBuserLoading,nav])

  const collapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  const multilineToSingleLine = (text) => {
    return text.replace(/\n/g, '#NEWLINE#');
  }

  const doneClick = () => {
    console.log(multilineToSingleLine(markdown))
  }


  const articleWidthClass = isCollapsed ? '' : '';
  const statsDisplayClass = isCollapsed ? 'w-10' : 'w-[750px]'
  const articleContentsClass = isCollapsed ? 'px-10 w-3/4' : 'px-5'
  const iconClass = isCollapsed ? 'justify-center' : 'flex-row-reverse'


  const editorChange = (e) => {
    setMarkdown(e.target.value)
  }

  return (
    <div style={style} className="Article justifycenter w-full flex ">
      <div className={"ArticleContentContainer h-full w-full flex justify-center shrink transition-all ease-in-out " + articleWidthClass}>
        <div className={"transition-all ease-in-out pt-5 pb-5 h-full w-full shrink overflow-y-scroll overflow-x-hidden noScroll " + articleContentsClass} >
          <RenderArticleContent markdown={markdown} />
        </div>
      </div>
      <div className={"pt-2 pl-2 grow overflow-scroll noScroll border-l-slate-800 border-l-2 transition-all ease-in-out " + statsDisplayClass}>
        <div className={"tran"}>
          <div className={"w-full flex " + iconClass}>
            <div className="h-4 cursor-pointer" onClick={collapse}>
              { isCollapsed ? <DoubleLeftArrowIcon /> : <DoubleRightArrowIcon /> }
            </div>
          </div>
          <button onClick={doneClick}>Done</button>
          <div className="">
            <textarea className="h-full w-full p-2" onChange={editorChange} value={markdown}></textarea>
          </div>
        </div>
      </div>
    </div>
  )
}