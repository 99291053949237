import Login from "../auth/Login";
import ChirpCreator from "./ChirpCreator";
import useWindowDimensions from '../hooks/useWindowDimensions'
import { useContext } from "react";
import { DisplaySettingsContext } from '../store/displaySettingsContext'
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/fbConfig";


export default function Admin() {
  
  const windowDimensions = useWindowDimensions()
  const { navHeight } = useContext(DisplaySettingsContext)
  const [FBuser,FBuserLoading] = useAuthState(auth);

  const heightOfContent = windowDimensions.height - navHeight;

  const style = {
    "height": heightOfContent
  }


  return (
    <div style={style} className="p-5 bg-slate-400">
      <Login />
      { 
        FBuserLoading === false && FBuser == null ? null :
        <ChirpCreator />
      }
    </div>
  )
}