import { useState } from "react"
import { fbSignIn, auth, fbSignOut } from "../config/fbConfig"
import { useAuthState } from 'react-firebase-hooks/auth';

export default function Login() {

  const [email,setEmail] = useState("")
  const [pass,setPass] = useState("")
  const [FBuser,FBuserLoading] = useAuthState(auth);

  const emailChange = (e) => {
    setEmail(e.target.value)
  }

  const passChange = (e) => {
    setPass(e.target.value)
  }

  const submit = (e) => {
    e.preventDefault()
    fbSignIn(email,pass).then(t => console.log(t))
    setEmail("")
    setPass("")
  }

  const signOut = () => {
    fbSignOut()
  }
  
  
  if(FBuserLoading) return null

  return (
    <div>
      { FBuser == null ? 
        <form className="flex flex-col space-y-2">
          <input className="textInput" type="email" placeholder="email" onChange={emailChange} value={email} />
          <input className="textInput" type="password" placeholder="password" onChange={passChange} value={pass} />
          <div>
            <input className="btn" onClick={submit} type="submit" />
          </div>
        </form> :
        <button className="btn" onClick={signOut}>Signout</button>
      }
    </div>
  )
}