import { useContext } from "react"
import QuickTake from "./QuickTake";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { DisplaySettingsContext } from "../../store/displaySettingsContext";
import useChirps from "../../hooks/useChirps";


export default function QuickTakes() {

  const windowDimensions = useWindowDimensions()
  const { navHeight } = useContext(DisplaySettingsContext)
  const [chirps] = useChirps()

  const heightOfContent = windowDimensions.height - navHeight;

  const style = {
    "height": heightOfContent
  }

  // const chirps = [
  //   {id:1,content:"Google is about to absolutely moon",tickers:['GOOG','GOOGL'],createdAt:new Date()},
  //   {id:2,content:"Snap On has quite a strong balance sheet upon quick look. Might be worth a further investigation but if margins look good then this could be a buy.",tickers:['SNA'],createdAt:new Date()},
  //   {id:3,content:"MESA has so many growth opportunities and its at least 30% cheaper than SKYW depending on the metric you look at.",tickers:['MESA','SKYW'],createdAt:new Date()},
  //   {id:4,content:"MESA has so many growth opportunities and its at least 30% cheaper than SKYW depending on the metric you look at.",tickers:['MESA','SKYW'],createdAt:new Date()},
  //   {id:5,content:"MESA has so many growth opportunities and its at least 30% cheaper than SKYW depending on the metric you look at.",tickers:['MESA','SKYW'],createdAt:new Date()},
  //   {id:6,content:"MESA has so many growth opportunities and its at least 30% cheaper than SKYW depending on the metric you look at.",tickers:['MESA','SKYW'],createdAt:new Date()},
  //   {id:7,content:"MESA has so many growth opportunities and its at least 30% cheaper than SKYW depending on the metric you look at.",tickers:['MESA','SKYW'],createdAt:new Date()},
  //   {id:8,content:"MESA has so many growth opportunities and its at least 30% cheaper than SKYW depending on the metric you look at.",tickers:['MESA','SKYW'],createdAt:new Date()}
  // ]

  return (
    <div style={style} className="w-full">
      <div className="w-full flex justify-center mt-5">
        <p className="text-5xl w-1/2 text-primary">Quick Ideas</p>
      </div>
      <div className="w-full h-full flex justify-center">
        <div className="w-1/2 flex flex-col">
          { chirps && chirps.map((chirp) => {
            return (
              <div key={chirp.id} className="self-center w-full py-5">
                <QuickTake chirp={chirp} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}