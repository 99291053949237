import { getHexFromTailwindColor } from "../../../../styles/colorClasses";
import { LineChart } from '@mui/x-charts/LineChart';
import moment from 'moment'


export default function ArticleChart(props) {


  const color = getHexFromTailwindColor('primary')



  const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  
  var data = props.data.replaceAll("'",'"')

  if(!isJson(data)) return <div>ArticleChart</div>

  data = JSON.parse(data)
  console.log(data)


  var xScaleType = 'linear'
  var xValueFormatter = (v) => v.toString();

  if(data.xScaleType === 'time'){

    xScaleType = 'time'
    xValueFormatter = (v) => {
      const retVal = moment(v).format('MM-DD-YY')
      return retVal
    }

    const newX = []
    for(var i = 0;i<data.x.length; i++){
      newX.push(moment(data.x[i], "MM/DD/YYYY").toDate())
    }
    data.x = newX
  }

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format

  return (
    <div className="w-full">
      <LineChart
        xAxis={[{ data: data.x, xScaleType, valueFormatter: xValueFormatter }]}
        series={[
          {
            curve: "linear",
            showMark: false,
            data: data.y,
            color,
            label:data.label,
            valueFormatter: (v) => currencyFormatter(v)
          },
        ]}
        // width={1000}
        height={500}

      />
    </div>
  )
}