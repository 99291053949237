import { insertDirective$, usePublisher, ButtonWithTooltip } from '@mdxeditor/editor'

export default function DisclaimerButton(){
  // grab the insertDirective action (a.k.a. publisher) from the 
  // state management system of the directivesPlugin
  const insertDirective = usePublisher(insertDirective$)

  return (
    <ButtonWithTooltip
      className="bg-white"
      onClick={() => {
        console.log("here")
        insertDirective({
          name: 'disclaimer',
          type: 'leafDirective',
          children: []
        })
      }}
    >Disclaimer</ButtonWithTooltip>
  )
}